import * as wjGrid from "@mescius/wijmo.grid";
import store from "@/store";
import { AutoComplete } from "@mescius/wijmo.input";
import { KEYS_CODE } from "@/constants/keyboard";
import {
  BLANK_ID,
  MESSAGE_NOT_NULL,
} from "@/constants/registerData";
import { validateMaximumCharactorOnlyFacility } from "@/utils/validate";
import debounce from "lodash/debounce";
import { math, formatBigNumber, formatEmission25Number, validateNumberFieldBySetting, $_helper_isNumberType_bySetting, formatNumberBySetting } from './wijmo.helper';
import {
  handlerFilterData,
  handlerAddFilterData,
  addFilterByPasted,
  getBranchId,
} from "@/concerns/utils/filter-data";

import { getListDbAddNew } from '@/concerns/utils/master-or-customize-db'
import { formatNumberRealNum } from '@/utils/convertNumber';
import i18n from '@/lang/i18n.js';
import { CellMaker } from "@mescius/wijmo.grid.cellmaker";
import { getWidthOfAttachedButton, getWidthOfSearchButton } from '@/utils/multiLanguage';
import { DB_TYPE } from '@/constants/dbType';
import { getDbRelationKeysByType } from '@/utils/sourceExternal';
import { handleLostFocus, handleSetCellAutoFill } from '@/utils/v2/csvImportHandler'
import { handleLostFocusBasicUnitPulldown, handleBasicUnitDroppedDownChanged, handleGotFocusBasicUnitPulldown } from '@/utils/registerData';
import { findOneByIdDataBasicUnit } from '@/utils/indexed-db/basicUnit';

export const blankData = (clientRowId) => {
  return {
    clientRowId: clientRowId,
    id: BLANK_ID,
    duration_id: null,
    month: null,
    organizational_division: null,
    company_name: null,
    business_name: null,
    business_content: null,
    wsu_source: null,
    wsu_unit: null,
    wsu_value: null,
    cpa_unit: null,
    cpa_value: null,
    cpa_source: null,
    emissions: null,
    year: null,
    db_customize_id: null,
    db_master_id: null,
    type: null,
    memo_1: null,
    memo_2: null,
    country: null,
    layer_3: null,
    layer_4: null,
    layer_5: null,
    layer_6: null,
    db_relation: null,
  };
};

const removeEditColumn = ["cpa_unit", "wsu_value", "wsu_unit", "emissions"];

const calcEmissions = (valueSource, valueEmission) => {
  if ($_helper_isNumberType_bySetting(valueSource) && $_helper_isNumberType_bySetting(valueEmission)) {
    const valSource = math.bignumber(formatNumberBySetting(valueSource));
    const valSale = math.bignumber(formatNumberBySetting(valueEmission))
    return formatBigNumber(math.evaluate(`${valSource.toString()} * ${valSale.toString()}`), 50)
  }

  return 0
}

const notNullInView = [
  "cpa_unit",
  "wsu_value",
  "wsu_unit"
];

let notNullList = [
  "organizational_division",
  "company_name",
  "cpa_unit",
  "wsu_value",
  "wsu_unit",
  "wsu_source",
  "cpa_value"
];

const notNullListDbSource = [
  "organizational_division",
  "company_name",
  "wsu_value",
  "wsu_unit",
  "wsu_source",
  "cpa_value"
];

const max25Charactor = [
  "cpa_value",
  "cpa_unit",
  // "wsu_value",
  "wsu_unit"
];

const max128Charactor = [
  // "company_name",
  // "business_name",
  "business_content",
  "cpa_source",
  "memo_1",
  "memo_2"
];

const numberField = [
  "cpa_value"
];

let isReverted = false;
let searchID = null;
const filterColumns = ["organizational_division", "company_name", "business_name", "country", "layer_3", "layer_4", "layer_5", "layer_6"];

const autoFillDbSource = (dbItem, currentItem) => {
  const { SERVICE_LINK, IDEA } = DB_TYPE;
  currentItem.idDbExternal = ![SERVICE_LINK, IDEA].includes(dbItem?.type) ? null : dbItem?.id
  if (dbItem) {
    if (dbItem?.type === SERVICE_LINK) {
      currentItem['detailExternalProductCode'] = currentItem.idDbExternal
      currentItem['cpa_value'] = dbItem.numberValue
    } else {
      currentItem['detailExternalProductCode'] = null
    }
    currentItem['wsu_source'] = dbItem.id;
    currentItem['cpa_unit'] = dbItem.unit;
    currentItem['wsu_value'] = formatNumberRealNum(dbItem.value_source);
    currentItem['wsu_unit'] = dbItem.unit_source;
    currentItem['emissions'] = calcEmissions(dbItem.value_source, currentItem.cpa_value);
  } else {
    currentItem['wsu_source'] = null;
    currentItem['cpa_unit'] = null;
    currentItem['wsu_value'] = null;
    currentItem['wsu_unit'] = null;
    currentItem['emissions'] = calcEmissions(currentItem.wsu_value, currentItem.cpa_value);
  }
}

const autoBindingDbSource = async (dataBinding) => {
  const { s, binding, row, cellData, currentItem, dataStore, dbAddNewInObject } = dataBinding
  if (binding === "cpa_value") {
    s.deferUpdate(() => {
      currentItem['emissions'] = calcEmissions(currentItem.wsu_value, currentItem.cpa_value);
    });
  }

  if (binding === "wsu_source") {
    let dbItem = await findOneByIdDataBasicUnit(cellData) || dataStore[cellData] || dbAddNewInObject[cellData]
    if (searchID && !dbItem) {
      dbItem = dataStore[searchID] || dbAddNewInObject[searchID]
    }
    s.deferUpdate(() => {
      autoFillDbSource(dbItem, currentItem)
    });
  }
}

let filterIndex = {};
const partern6 = {
  initializeGrid(dataProps) {
    const { flexgrid, itemCount, branchData = {}, getNewBbStore } = dataProps
    let filterIndex = {};
    let selectedFirst = null;
    let previousCellData = null;
    let checkFilterDataAfterPasted = {};

    flexgrid.scrollPositionChanged.addHandler(debounce((s, e) => {
      if (!store.state.registerData.isFullScreen) {
        return;
      }

      if (s.viewRange.bottomRow >= s.rows.length - 1) {
        s.deferUpdate(() => {
          const lastClientId = flexgrid.itemsSource.itemCount;

          for (let index = 1; index <= itemCount; index++) {
            s.itemsSource.addNew(blankData(lastClientId + index));
          }

          s.itemsSource.commitNew();
          s.itemsSource.clearChanges();
        });
      }
    }, 100));

    document.addEventListener("keydown", (e) => {
      if (
        (e.metaKey || e.ctrlKey) &&
        [KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW, KEYS_CODE.ENTER].includes(e.keyCode)
      ) {
        e.preventDefault();
      }
    });

    flexgrid.hostElement.addEventListener("keydown", (e) => {
      // console.log('keydown: ', e);
      if (e.metaKey || e.ctrlKey) {
        if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
          const currentSelection = flexgrid.selection;
          const cellRange = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col);
          flexgrid.selection = cellRange;

          // re-select after add more
          setTimeout(() => {
            flexgrid.selection = cellRange;
          }, 200);
        } else if (e.keyCode === KEYS_CODE.UP_ARROW) {
          const currentSelection = flexgrid.selection;
          const cellRange = new wjGrid.CellRange(0, currentSelection.col);
          flexgrid.selection = cellRange;
        } else if (e.keyCode === KEYS_CODE.RIGHT_ARROW) {
          const currentSelection = flexgrid.selection;
          const cellRange = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1);
          flexgrid.selection = cellRange;
        } else if (e.keyCode === KEYS_CODE.LEFT_ARROW) {
          const currentSelection = flexgrid.selection;
          const cellRange = new wjGrid.CellRange(currentSelection.row, 1);
          flexgrid.selection = cellRange;
        }
      }
      if (e.keyCode === KEYS_CODE.ENTER) {
        if (flexgrid.selection.row === flexgrid.rows.length - 1) {
          const lastClientId = flexgrid.itemsSource.itemCount;

          flexgrid.deferUpdate(() => {
            flexgrid.itemsSource.addNew(blankData(lastClientId + 1));

            flexgrid.itemsSource.commitNew();
            // flexgrid.itemsSource.clearChanges();
          });
        }
      }


    }, false);

    flexgrid.pasted.addHandler((s, e) => {
      const { col, col2, row, row2 } = e.range

      for (let colIndex = col; colIndex <= col2; colIndex++) {
        for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
          const binding = s.columns[colIndex].binding;
          if (filterColumns.includes(binding)) {
            if (s.getCellData(rowIndex, colIndex, false) !== null || s.getCellData(rowIndex, colIndex, false) !== undefined) {
              addFilterByPasted(s, e, colIndex, rowIndex, filterIndex)
            }
          }
        }
      }
    })

    flexgrid.cellEditEnded.addHandler((s, e) => {
      let column = s.columns[e.col];
      const binding = column.binding

      if (filterColumns.includes(binding)) {
        handlerFilterData(s, e, branchData?.filterPatternList, filterIndex, selectedFirst, previousCellData, isReverted);
      }
    });

    flexgrid.beginningEdit.addHandler((s, e) => {
      let item = s.rows[e.row].dataItem;
      let column = s.columns[e.col];
      // auto ++ serial number
      if (!item.index) {
        item.index = flexgrid?.rows?.length - 1 || 1;
      }

      if (removeEditColumn.includes(column.binding)) {
        e.cancel = true;
      }

      if (filterColumns.includes(column.binding)) {
        const { row, col } = e.range;
        const cellData = s.getCellData(row, col, false);
        previousCellData = cellData;
        handlerAddFilterData(s, e, filterIndex, selectedFirst, store.state.registerData, branchData);
      }
    });
  },

  header(dataProps) {
    const {
      registerData,
      listLayout,
      branchData,
      externalSourcePopup,
      attachFilePopup,
      dbStore,
      isRemoveStatusColumn,
      grid,
      serviceLinkPopup,
      basicUnitSearchPopup,
      uniqueOriginalDb,
      isPastingDbIdea = false,
      basicUnitData,
      typeDbItemAddNew,
      uniqueOriginalAllDbUnit,
      listUniqueNameBasicForPulldown,
      handleAddDbItemToPullDown,
    } = dataProps

    let companyMap = new wjGrid.DataMap(branchData.company_name, "value", "value");
    companyMap.getDisplayValues = (dataItem) => {
      let validCompany = branchData?.company_name?.filter(
        (company) => company.organizational_division === dataItem?.organizational_division
      );
      return validCompany.map((item) => {
        return {
          value: item.value
        };
      });
    };

    companyMap.getDisplay = (dataItem) => {
      let validCompany = branchData?.company_name?.filter(
        (company) => company.organizational_division === dataItem
      );
      return validCompany.map((item) => {
        return {
          value: item.value
        };
      });
    };

    let businessMap = new wjGrid.DataMap(branchData.business_name, "value", "value");
    businessMap.getDisplayValues = (dataItem) => {
      let validBusiness = branchData?.business_name?.filter(
        (company) => company.company_name === dataItem?.company_name
      );

      let uniqueItem = [...new Map(validBusiness.map((item) => [item["name"], item])).values()];
      return uniqueItem.map((item) => {
        return {
          value: item.value,
          key: item.value
        };
      });
    };

    businessMap.getDisplay = (dataItem, organizational_division) => {
      let validBusiness = branchData?.business_name?.filter(
        (business) => business.company_name === dataItem && business.organizational_division === organizational_division
      );

      let uniqueItem = [...new Map(validBusiness.map((item) => [item["name"], item])).values()];
      return uniqueItem.map((item) => {
        return {
          value: item.value,
          key: item.value
        };
      });
    };

    const listDbAddNew = getListDbAddNew(dbStore.dbExternalAdded) // get all db add new
    const dbList = uniqueOriginalAllDbUnit?.length > 0 ? uniqueOriginalAllDbUnit : [...uniqueOriginalDb, ...listDbAddNew]

    return [
      {
        header: "連番",
        binding: "id", // id
        minWidth: 40,
        maxWidth: 65,
        allowSorting: false,
        isReadOnly: true,
        visible: false,
        isRequired: false,
      },
      {
        header: i18n.t('56_pattern.table_organizational_division'),
        binding: "organizational_division", // phan loai to chuc
        minWidth: 96,
        maxWidth: 400,
        allowResizing: true,
        allowSorting: false,
        wordWrap: true,
        isRequired: false,
        cssClassAll: 'required-field',
        dataMap: new wjGrid.DataMap(registerData.listType, "key", "value"),
        editor: new AutoComplete(document.createElement("div"), {
          placeholder: i18n.t('56_pattern.placeholder'),
          itemsSource: registerData.listType,
          selectedValuePath: "key",
          displayMemberPath: "value",
          maxItems: 1000,
          minLength: 1
        })
      },
      {
        header: i18n.t('56_pattern.table_company_name'),
        binding: "company_name",
        minWidth: 85,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        dataMap: companyMap,
        cssClassAll: 'required-field',
        editor: new AutoComplete(document.createElement("div"), {
          placeholder: i18n.t('56_pattern.placeholder'),
          itemsSource: branchData?.company_name,
          selectedValuePath: "value",
          displayMemberPath: "value",
          maxItems: 1000,
          minLength: 1
        })
      },
      ...listLayout,
      {
        header: i18n.t('56_pattern.table_business_content'),
        binding: "business_content",
        minWidth: 96,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        multiLine: true
      },
      {
        header: i18n.t('56_pattern.table_memo_1'), // memo1
        binding: "memo_1",
        minWidth: 90,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true
      },
      {
        header: i18n.t('56_pattern.table_memo_2'), // memo2
        binding: "memo_2",
        minWidth: 90,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true
      },
      {
        header: i18n.t('56_pattern.table_exhaust_original'),
        align: "center",
        columns: [
          {
            header: i18n.t('basic_unit_library.label_search_basic_unit'),
            binding: "basic_unit_search",
            maxWidth: getWidthOfSearchButton(i18n.locale),
            minWidth: getWidthOfSearchButton(i18n.locale),
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClassAll: "search-detail hide-filter",
            cellTemplate: CellMaker.makeButton({
              text:
                `<div class="search-container">
                  ${i18n.t('register_data.button_search')}
                  <img src="${require('@/assets/icons/pulldown/search-external.svg')}" alt="search icon" class="search-icon">
                </div>`,
              click: (e, ctx) => basicUnitSearchPopup(ctx, 'wsu_source')
            }),
          },
          {
            header: i18n.t('basic_unit_library.basic_unit_name'),
            binding: "wsu_source",
            minWidth: 300,
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClassAll: 'required-field',
            dataMap: new wjGrid.DataMap(dbList, "id", "source"),
            editor: new AutoComplete(document.createElement("div"), {
              placeholder: i18n.t('56_pattern.placeholder'),
              itemsSource: dbList,
              selectedValuePath: "id",
              displayMemberPath: "source",
              maxItems: 1000,
              minLength: 1,
              selectedIndex: -1,
              isDroppedDownChanged: (sender) => {
                handleBasicUnitDroppedDownChanged({ sender, dataProps })
              },
              lostFocus: async (sender, ctx) => {
                handleLostFocusBasicUnitPulldown({ sender, ctx, dataProps });
                searchID = await handleLostFocus({ listUniqueNameBasicForPulldown, handleAddDbItemToPullDown, ctx, sender, dbStore, registerData, listLayout, branchData, externalSourcePopup, attachFilePopup, serviceLinkPopup, grid, isRemoveStatusColumn, uniqueOriginalDb, basicUnitData }, partern6.header)
                handleSetCellAutoFill(searchID, grid, ctx.row, autoFillDbSource)
              },
              gotFocus: (sender) => {
                handleGotFocusBasicUnitPulldown({ sender, dataProps })
              },
            })
          },
          {
            header: i18n.t('56_pattern.table_wsu_value'),
            binding: "wsu_value",
            minWidth: 70,
            maxWidth: 400,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClass: "auto-increment",
            align: "right",
            cssClassAll: 'required-field',
          },
          {
            header: i18n.t('56_pattern.table_wsu_unit'),
            binding: "wsu_unit",
            minWidth: 70,
            maxWidth: 400,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClass: "auto-increment",
            cssClassAll: 'required-field',
          },
        ]
      },
      {
        header: i18n.t('56_pattern.table_capital_good_purchase'),
        align: "center",
        columns: [
          {
            header: i18n.t('56_pattern.table_cpa_value'),
            binding: "cpa_value",
            minWidth: 70,
            maxWidth: 400,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            align: 'right',
            cssClassAll: 'required-field',
          },
          {
            header: i18n.t('56_pattern.table_cpa_unit'),
            binding: "cpa_unit",
            minWidth: 70,
            maxWidth: 400,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClass: "auto-increment",
            cssClassAll: 'required-field',
          },
          {
            header: i18n.t('56_pattern.table_cpa_source'),
            binding: "cpa_source",
            minWidth: 83,
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true
          }
        ]
      },
      {
        header: i18n.t('56_pattern.table_emissions'),
        binding: "emissions",
        cssClass: "auto-increment",
        cssClassAll: "emission",
        align: "right",
        minWidth: 90,
        maxWidth: 400,
        allowSorting: false,
        isRequired: false
      },
      {
        header: i18n.t('56_pattern.table_attach_file'),
        binding: "attach_file",
        cssClass: 'auto-increment',
        minWidth: getWidthOfAttachedButton(i18n.locale),
        maxWidth: getWidthOfAttachedButton(i18n.locale),
        allowSorting: false,
        isRequired: false,
        cssClassAll: "btn-db attach-file hide-filter",
        cellTemplate: (ctx, el) => {
          let text = i18n.t('56_pattern.table_attach_file');
          if (ctx.item?.evidence) {
            text = `<div class="file-attached-container">
              <img src="${require('@/assets/icons/file_attached.svg')}" alt="file attached icon" class="file_attached">
            </div>`
          }
          const button = CellMaker.makeButton({
            text: text,
            click: (e, context) => attachFilePopup(e, context)
          })
          return button(ctx, el);
        }
      },
      {
        header: i18n.t('user_management.table_status'),
        binding: "status",
        minWidth: 100,
        width: 100,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        cssClassAll: 'status-field',
      }
    ];
  },
  handlerDataOnTable(dataProps) {
    const {
      dataTable,
      bindingLayout,
      isSaveTemplate,
      branchData,
      isGetBranchDetail,
      dbStore,
      itemAdded,
      dbItem
    } = dataProps
    let databaseTypeObject = {};

    if (itemAdded) {
      databaseTypeObject = itemAdded
      databaseTypeObject.id = databaseTypeObject?.id
    } else {
      databaseTypeObject = dbItem || {};
      dataTable.idDbExternal = null
      dataTable.db_relation = null
    }

    dataTable.wsu_source = databaseTypeObject?.id || null
    dataTable.wsu_unit = databaseTypeObject.unit_source || null;
    dataTable.wsu_value = formatNumberRealNum(databaseTypeObject?.value_source) || formatNumberRealNum(databaseTypeObject.wsu_value) || null;
    dataTable.cpa_unit = databaseTypeObject.unit || null;

    if (!isGetBranchDetail) return databaseTypeObject
    const emissions = calcEmissions(dataTable.wsu_value, dataTable.cpa_value);
    // auto set data for layout
    const layoutsData = {}
    bindingLayout.forEach(key => {
      layoutsData[key] = dataTable[key] || null
    })
    const branchId = isGetBranchDetail ? getBranchId(branchData.filterPatternList, {
      organizational_division: dataTable.organizational_division,
      company_name: dataTable.company_name,
      ...layoutsData
    }) : {};

    if (typeof dataTable.evidence === 'string') {
      dataTable.evidence = JSON.parse(dataTable.evidence);
    }
    let dataTableItemCustom = {
      id: dataTable.id,
      company_name: dataTable.company_name || null,
      organizational_division: dataTable.organizational_division || null,
      emissions: formatEmission25Number(emissions),
      business_content: dataTable.business_content || null,
      wsu_source: databaseTypeObject?.origin_id || null,
      wsu_unit: dataTable.wsu_unit,
      wsu_value: formatNumberBySetting(dataTable.wsu_value),
      cpa_source: dataTable.cpa_source || null,
      cpa_unit: dataTable.cpa_unit || null,
      cpa_value: isSaveTemplate ? '' : formatNumberBySetting(dataTable.cpa_value) === 0 ? '0' : formatNumberBySetting(dataTable.cpa_value) || null,
      contractor_id: store.state.userData.contractor, // from store
      month: dbStore.isFlagUpdateDataListEmission ? dataTable.month : store.state.registerData.month, // from store
      year: dbStore.isFlagUpdateDataListEmission ? dataTable.year : store.state.registerData.yearSelected,
      db_customize_id: databaseTypeObject?.origin_id || null,
      db_master_id: databaseTypeObject?.origin_id || null,
      type: databaseTypeObject?.type,
      memo_1: dataTable.memo_1 || null,
      memo_2: dataTable.memo_2 || null,
      ...layoutsData,
      branch_id: branchId?.id || null,
      db_relation: dataTable.db_relation || null,
      evidence: dataTable.evidence || null,
      status: dataTable.status ? 1 : 0,
    };

    if (dataTable.evidence) {
      if (dataTable.is_primary_data) {
        delete dataTable.evidence.value;
      }
      else {
        dataTableItemCustom.evidence.value = dataTable[this.bindingValueColumn];
      }
    }

    if (dataTableItemCustom.type > 0) {
      dataTableItemCustom.db_customize_id = null
    } else {
      dataTableItemCustom.db_master_id = null
    }
    if (dataTableItemCustom.type === DB_TYPE.SERVICE_LINK || dataTableItemCustom.type === DB_TYPE.IDEA || dataTable.idDbExternal) {
      const dbRelationKey = getDbRelationKeysByType(dataTableItemCustom.type);
      dataTableItemCustom.db_relation = {
        [dbRelationKey]: databaseTypeObject.origin_id
      }
      // dataTableItemCustom.type = 2
      dataTableItemCustom.db_customize_id = null
      dataTableItemCustom.db_master_id = null
    }

    return dataTableItemCustom;
  },

  addBlankItemsToView: (view, count) => {
    const lastClientId = view.itemCount;
    for (let index = 1; index <= count; index++) {
      view.addNew(blankData(lastClientId + index));
    }

    view.commitNew();
    // view.clearChanges();
  },

  filterColumns: [
    "organizational_division",
    "company_name",
    "business_name",
    "business_content",
    "cpa_unit",
    "wsu_unit",
    "wsu_source"
  ],
  getError(item, propName, dbItem, validateErrorBtn = {}) {
    const { itemsInView = [], branchData = [], isCheckMappingFacility = false, itemsInViewError = [] } = validateErrorBtn;
    let isDataDbIdea = item.wsu_source && dbItem?.type === 2

    const listItemvalidate = item.db_relation || isDataDbIdea ? notNullListDbSource : notNullList

    if (listItemvalidate.includes(propName)) {
      if (item[propName] === null || item[propName] === "" || item[propName] === undefined || !item[propName] && item[propName] !== 0) {
        return MESSAGE_NOT_NULL;
      }
    }

    if (numberField.includes(propName)) {
      if (!item[propName] && item[propName] !== 0) {
        return MESSAGE_NOT_NULL;
      } else {
        return validateNumberFieldBySetting(String(item[propName]));
      }
    }

    if (max25Charactor.includes(propName)) {
      if (numberField.includes(propName)) {
        return validateNumberFieldBySetting(String(item[propName]));
      }
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 25);
    }

    if (max128Charactor.includes(propName)) {
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 128);
    }

    if (isCheckMappingFacility && filterColumns.includes(propName)) {
      const layoutsData = {}
      itemsInView.forEach(key => {
        layoutsData[key] = item[key] || null
      })
      const branchId = getBranchId(branchData.filterPatternList, {
        organizational_division: item.organizational_division,
        company_name: item.company_name,
        ...layoutsData
      });

      if (!branchId) {
        return i18n.t('new_validate.error_mapping_layer', { listLayer: itemsInViewError.map(item => item)?.join('/') })
      }
    }
    return null;
  },

  cellInputInView(bindingLayout) {
    return [
      'organizational_division',
      'company_name',
      'business_content',
      'memo_1',
      'memo_2',
      'cpa_unit',
      'wsu_unit',
      'cpa_value',
      'cpa_source',
      'wsu_source',
      'attach_file',
      ...bindingLayout
    ];
  },

  cellRequireInView(bindingLayout) {
    return [
      'organizational_division',
      'company_name',
      'business_content',
      'memo_1',
      'memo_2',
      'cpa_unit',
      'wsu_unit',
      // 'wsu_source',
      ...bindingLayout
    ];
  },

  autoFields: [
    'cpa_unit',
    'wsu_value',
    'wsu_unit',
    'wsu_source',
  ],

  patternUrl: '/pattern-s3-c2/',
  valueSource: 'wsu_source',

  calcEmissions(rowData) {
    return calcEmissions(rowData.wsu_value, rowData.cpa_value)
  },

  bindingValueColumn: 'cpa_value',

  setFilterIndex(filterIndexValue) {
    filterIndex = filterIndexValue;
  },
  updateFilterIndex(filterIndexValue) {
    filterIndex = { ...filterIndex, ...filterIndexValue }
  },
  columnsCalcEmission: ['wsu_value', 'cpa_value'],
  isUseBasicUnit: true,
  getAutoBindingDbSource(dataProps) {
    return autoBindingDbSource(dataProps);
  }
};

export default partern6;
